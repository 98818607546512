import clsx from 'clsx';
import { unescape } from 'html-escaper';

import Markdown from 'components/common/Markdown';
import ParagraphHeader from 'components/ParagraphHeader';
import Link from 'components/common/Link';
import Button from 'components/common/Button';

import { TableProps } from 'interfaces/cms/content';
import styles from './Table.module.scss';

function Table({ header, content, background, button }: TableProps) {
  return (
    <div
      className={clsx(styles.customTable, 'py-[1.875rem]')}
      style={{ backgroundColor: background }}
    >
      {header && (
        <>
          <ParagraphHeader {...header} />
          <div className="mb-[1.875rem]" />
        </>
      )}

      {content && (
        <div
          className={clsx(
            styles.customTable__content,
            'max-w-[69.375rem] mx-auto'
          )}
        >
          <div dangerouslySetInnerHTML={{ __html: unescape(content) }} />
        </div>
      )}

      {button && (button.buttonUrl || button.buttonPageSlug) && (
        <>
          <div className="mt-[1.875rem]" />

          <div className="text-center">
            <Link
              href={
                button.buttonPageSlug
                  ? button.buttonPageSlug.slug
                  : button.buttonUrl
              }
              rel={button.buttonLinkRel}
              isExternalLink={!!button.buttonUrl}
            >
              <Button {...button} btnStyle="min-w-[16.75rem]" />
            </Link>
          </div>

          {button.disclaimer && (
            <>
              <div className="mt-[0.9375rem]" />
              <Markdown
                textAlign="center"
                md={button.disclaimer.title}
                textColor={button.disclaimer.color}
                hasLockIcon={button.disclaimer.lockIcon}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Table;
